@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100%;
  min-height: 100vh;
  background-color: theme("colors.secondary.100");
}
html {
  height: 100%;
}
.tox-tinymce-aux .tox-notifications-container {
  display: none;
}
select {
  appearance: none;
  background-color: theme("colors.secondary.200");
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23363a41' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border-radius: theme("borderRadius.2xl");
  display: block;
  padding-inline: theme("padding.6");
  padding-block: theme("padding.1");
}
table tr td:first-child,
table thead th:first-child {
  border-top-left-radius: theme("borderRadius.2xl");
  border-bottom-left-radius: theme("borderRadius.2xl");
}
table th {
  text-align: left;
  background-color: theme("colors.secondary.400");
}

@screen xl {
  table {
    font-size: theme("fontSize.base") !important;
  }
}
table {
  font-size: theme("fontSize.xs");
}
@screen xl {
  table tr td,
  table th {
    padding: theme(padding.6) !important;
  }
}
table tr td,
table th {
  padding-block: theme(padding.2);
  padding-inline: theme(padding.4);
}

table tr td:last-child,
table thead th:last-child {
  border-top-right-radius: theme("borderRadius.2xl");
  border-bottom-right-radius: theme("borderRadius.2xl");
}

.tox.tox-tinymce {
  border: 0;
}
.tox.tox-tinymce .tox-edit-area__iframe {
  padding: theme("padding.3");
  background-color: theme("colors.secondary.150");
}
.tox:not(.tox-tinymce-inline).tox-tinymce .tox-editor-header,
.tox:not(.tox-tinymce-inline).tox-tinymce .tox-editor-header .tox-menubar,
.tox:not(.tox-tinymce-inline).tox-tinymce
  .tox-editor-header
  .tox-toolbar-overlord,
.tox:not(.tox-tinymce-inline).tox-tinymce
  .tox-editor-header
  .tox-toolbar__primary {
  background-color: theme("colors.secondary.300");
}
